<template>
  <b-container fluid>
    <b-row class="p-3">
      <b-col>
        <b-row class="d-flex" align-v="center">
          <h1 class="mr-5">Banners</h1>
          <div>
            <b-btn
              variant="primary"
              to="/admin/banners/create"
            >Add New</b-btn>
          </div>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-center p-3">
      <b-table
        responsive
        striped
        hover
        :items="items"
        :fields="fields"
      >
        <template #cell(actions)="data">
          <b-row>
            <b-link
              style="margin-right:5px;"
              :href="`/admin/banners/${data.item.id}`"
            >
              <i
                class="fas fa-edit fa-lg"
                title="Edit"
              />
            </b-link>
          </b-row>
        </template>
      </b-table>
    </b-row>
  </b-container>
</template>

<script>
import { makeGetRequest } from '@/util'

export default {
  name: 'BannersList',
  data () {
    return {
      items: [],
      fields: [
        'name',
        'status',
        'actions'
      ]
    }
  },
  methods: {
    async getItems () {
      const url = `${this.$config.apiUrl}/banners`
      const items = await makeGetRequest(url)
      this.items = items
    }
  },
  created () {
    this.getItems()
  }
}
</script>

<style>
</style>
